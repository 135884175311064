import React from 'react'
import HeroSection from '../../HeroSection';
import { homeObjOne, homeObjTwo, homeObjThree, homeObjFour} from './Data';
import './../About/About.css'

const Service = () => {
  return (
    <>
    <div class="TopHeading">
        <h1>Our Services</h1>
    </div>
      <HeroSection {...homeObjOne} />
      <HeroSection {...homeObjTwo} />
      <HeroSection {...homeObjThree} />
      <HeroSection {...homeObjFour} />
    </>
  )
}

export default Service