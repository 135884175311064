import React from 'react';
import HeroSection from '../../HeroSection';
import { homeObjOne} from './Data';
import './About.css'

function Products() {
  return (
    <>
    <div class="TopHeading">
        <h1>About Us</h1>
        <p> We are a team of passionate & full-time experts dealing with airtime and payment solutions for businesses.</p>
    </div>
      <HeroSection {...homeObjOne} />
    </>
  );
}

export default Products;