import React from 'react'
import './Networks.css'
import logo from '../images/bp-safaricom.png'
import logo1 from '../images/bp-airtel.png'
import logo2 from '../images/bp-telkom.png'

const Networks = () => {
  return (
    <div className='network-container'>
        <h1>Across All Networks</h1>
        <div className='network-image-wrapper'>
          <div className='network-image-items'>
            <img  src={logo} alt='Safaricom'/>
          </div>
          <div className='network-image-items'>
            <img  src={logo1} alt='Airtel'/>
          </div>
          <div className='network-image-items'>
            <img  src={logo2} alt='Telkom'/>
          </div>
        </div>
      </div>
  )
}

export default Networks