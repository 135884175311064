import React from 'react'
import './Contact.css'
import pic from '../../../images/bp-contact.png'
import { IconContext } from 'react-icons/lib';
import { MdLocationOn, MdEmail, MdPhone } from 'react-icons/md';

const Contact = () => {
  return (
    <IconContext.Provider value={{ color: '#fff', size: 30 }}>
    <div class="TopHeading">
        <h1>Contact</h1>
    </div>
    <div className='form-container'>
      <div className='form-content-left'>
        <img className='form-img' src={pic} alt='Contact us' />
      </div>
      <div className='form-content-right'>
      <div className='form'>
        <h1>
          Get in Touch!
        </h1>
        <div className='form-item' >
            <MdLocationOn className='form-icon' />
            Embakasi south - Pipeline next to Jackmill supermarket
        </div>
        <div className='form-item' >
            <MdEmail className='form-icon' />
            info@binarypay.co.ke
        </div>
        <div className='form-item' >
            <MdPhone className='form-icon' />
            0705929295
        </div>
        </div>
    </div>
    </div>
  </IconContext.Provider>
  )
}

export default Contact