import './App.css';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/pages/HomePage/Home';
import About from './components/pages/About/About';
import Footer from './components/pages/Footer/Footer';
import Service from './components/pages/Service/Service';
import Contact from './components/pages/Contact/Contact';

function App() {
  return (
    <Router> 
      <Navbar/>
      <Routes>
        <Route path='/' element={<Home/>} />
        <Route path ='/about' element={<About/>} />
        <Route path ='/service' element={<Service/>} />
        <Route path ='/contact' element={<Contact/>} />
      </Routes>
      <Footer/>
    </Router>
  );
}

export default App;
