export const homeObjOne = {
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Airtime sale',
    headline: 'Easy purchase 24/7',
    description:
      'Get access to airtime at your own comfort. Use our paybill to recharge your number to any network. There is zero transaction fee below sh100',
    imgStart: 'start',
    img: require('../../../images/bp-poster.png'),
    alt: 'Steps to buy airtime'
  };
  
  export const homeObjTwo = {
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Till Number registration',
    headline: 'Get a buy goods number for ease of payment',
    description:
      'We have you covered no matter what you sell. Allow customers to purchase your goods through till number at zero transaction cost',
    imgStart: '',
    img: require('../../../images/bp-till.png'),
    alt: 'Step to buy airtime'
  };
  
  export const homeObjThree = {
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Paybill registration',
    headline: 'Super fast and simple onboarding process',
    description:
      "The Paybill service is a cash collection service that allows your organisation to collect money on a regular basis from your customers through M-PESA. Through Paybill numbers, you can serve your clients much better as your customers are able to pay for services with ease and timeliness.",
    imgStart: 'start',
    img: require('../../../images/bp-paybill.png'),
    alt: 'Vault'
  };
  
  export const homeObjFour = {
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Mpesa Integration',
    headline: 'Accept payments in your website through mpesa',
    description:
      'Never ever have to worry again about payments. Our team of experts will succesfully integrate mpesa to your commerce site within no time',
    imgStart: '',
    img: require('../../../images/bp-integration.png'),
    alt: 'Vault'
  };