import React from 'react';
import { Button } from './Button';
import './Products.css';
import { IoIosPhonePortrait } from 'react-icons/io';
import { FiPhoneOutgoing } from 'react-icons/fi';
import { RiSecurePaymentLine } from "react-icons/ri";
import { SlSettings } from 'react-icons/sl';
import { IconContext } from 'react-icons/lib';
import { Link } from 'react-router-dom';
import {animateScroll as scroll} from 'react-scroll'

function Products() {

  const toggleService = () => {
    scroll.scrollTo()
}

  return (
    <IconContext.Provider value={{ color: '#fff', size: 50 }}>
      <div className='product__section'>
        <div className='product__wrapper'>
          <h1 className='product__heading'>Services</h1>
          <div className='product__container'>
            <Link to='/service' className='product__container-card' onClick={toggleService}>
              <div className='product__container-cardInfo'>
                <div className='icon'>
                  < FiPhoneOutgoing/>
                </div>
                <h3>Airtime</h3>
                <p>Buy airtime anywhere and anytime.</p>
                <Button buttonSize='btn--wide' buttonColor='primary'>
                  Learn More
                </Button>
              </div>
            </Link>
            <Link to='/service' className='product__container-card' onClick={toggleService}>
              <div className='product__container-cardInfo'>
                <div className='icon'>
                  <IoIosPhonePortrait />
                </div>
                <h3>Till number</h3>
                <p>Give your customers a cashless way of payment.</p>
                <Button buttonSize='btn--wide' buttonColor='primary'>
                  Learn More
                </Button>
              </div>
            </Link>
            <Link to='/service' className='product__container-card' onClick={toggleService}>
              <div className='product__container-cardInfo'>
                <div className='icon'>
                  <RiSecurePaymentLine />
                </div>
                <h3>Paybill Number</h3>
                <p>A more structured way of payment with account Number.</p>
                <Button buttonSize='btn--wide' buttonColor='primary'>
                  Learn More
                </Button>
              </div>
            </Link>
            <Link to='/service' className='product__container-card' onClick={toggleService}>
              <div className='product__container-cardInfo'>
                <div className='icon'>
                  <SlSettings/>
                </div>
                <h3>Mpesa Integration</h3>
                <p>Integrate mpesa to your already exiting site.</p>
                <Button buttonSize='btn--wide' buttonColor='primary'>
                  Learn More
                </Button>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </IconContext.Provider>
  );
}
export default Products;