export const homeObjOne = {
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Kamata Credo Bila Stress',
    headline: 'Buy Airtime Using PAYBILL NUMBER 929295',
    description:
      'Account Number is the phone number to top up',
    buttonLabel: 'Contact',
    imgStart: '',
    img: require('../../../images/bphero.png'),
    alt: 'phone'
  };
  
  export const homeObjThree = {
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Easy to use',
    headline: 'Super fast and simple purchasing process',
    description:
      "Get yor airtime in under 1 minute. All you need to do is put the Paybill number and Account number.",
    buttonLabel: 'Start Now',
    imgStart: 'start',
    img: require('../../../images/bp-airtime-boy.png'),
    alt: 'East to use'
  };
  
  export const homeObjFour = {
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Amazing products',
    headline: 'Every product is tailored to suit your needs',
    description:
      'Never ever have to worry again about airtime and payments. We are here to ensure you provide the best to your customers.',
    imgStart: '',
    img: require('../../../images/bp-all-products.png'),
    alt: 'Amazing Services'
  };