export const homeObjOne = {
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: '',
    headline: 'Our Story',
    description:
      'We provide Airtime to customers though our paybill number and provide payment solutions to businesses since 2021. Our payment solutions are till numbers and paybill numbers and also we do mpesa intergration ',
    imgStart: '',
    img: require('../../../images/bp-aboutus.png'),
    alt: 'Abou Binarypay'
  };