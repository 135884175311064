import React from 'react';
import {animateScroll as scroll} from 'react-scroll'
import './Footer.css';
import { Link } from 'react-router-dom';
import logo from '../../../images/binarylogo-white.png';
import {
  FaFacebook,
  FaInstagram,
} from 'react-icons/fa';


function Footer() {

    const toggleHome = () => {
        scroll.scrollToTop()
    }
    const toggleAbout = () => {
      scroll.scrollTo()
  }
  const toggleContact = () => {
    scroll.scrollTo()
}

  return (
    <div className='footer-container'>
      <div className='footer-links'>
        <div className='footer-link-wrapper'>
          <div className='footer-link-items'>
            <h2>Services</h2>
            <Link to='/service'>Airtime</Link>
            <Link to='/service'>Till number</Link>
            <Link to='/service'>Paybill number</Link>
          </div>
          <div className='footer-link-items'>
            <h2>Company</h2>
            <Link to='/contact' onClick={toggleContact}>Contact Us</Link>
            <Link to='/about' onClick={toggleAbout}>About Us</Link>
          </div>
        </div>
      </div>
      <section className='social-media'>
        <div className='social-media-wrap'>
          <div className='footer-logo'>
            <Link to='/' className='social-logo' onClick={toggleHome}>
            <img className='navbar-icon' src={logo} alt='BinaryPay'/>
            </Link>
          </div>
          <small className='website-rights'>{new Date().getFullYear()} BinaryPay &copy; All rights reserved.</small>
          <div className='social-icons'>
            <Link
              className='social-icon-link'
              to='/'
              target='_blank'
              aria-label='Facebook'
            >
              <FaFacebook />
            </Link>
            <Link
              className='social-icon-link'
              to='/'
              target='_blank'
              aria-label='Instagram'
            >
              <FaInstagram />
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;